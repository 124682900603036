<template>
  <nav class="flex items-center">
    <div class="w-28">{{ from }} - {{ to }} de {{ totalItems }}</div>

    <ul class="flex items-center space-x-3 mx-auto">
      <li
        class="border rounded-full"
        :class="{
          'border border-gray-400': page != 1,
          'border-gray-300 text-gray-300 cursor-not-allowed': page == 1,
        }"
      >
        <router-link
          v-if="page != 1"
          class="h-8 w-8 flex items-center justify-center"
          :to="{ name: routeName, query: { ...urlQuery, page: page - 1 } }"
          rel="next"
        >
          <svg class="w-3 h-3 stroke-current" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </router-link>
        <span v-else class="h-8 w-8 flex items-center justify-center">
          <i class="tmlps-i-arrow-left text-2xl"></i>
        </span>
      </li>

      <template v-for="n in totalPages">
        <li class="bg-white shadow-md rounded-full" :key="n" v-if="page === n">
          <span class="h-8 w-8 flex items-center justify-center">{{ n }}</span>
        </li>

        <li class="border border-gray-400 rounded-full" :key="n" v-else>
          <router-link
            class="h-8 w-8 flex items-center justify-center"
            :to="{ name: routeName, query: { ...urlQuery, page: n } }"
            rel="next"
          >
            {{ n }}
          </router-link>
        </li>
      </template>

      <li
        class="border rounded-full"
        :class="{
          'border border-gray-400': hasMorePages,
          'border-gray-300 text-gray-300 cursor-not-allowed': !hasMorePages,
        }"
      >
        <router-link
          v-if="hasMorePages"
          class="h-8 w-8 flex items-center justify-center"
          :to="{ name: routeName, query: { ...urlQuery, page: page + 1 } }"
          rel="next"
        >
          <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </router-link>
        <span v-else class="h-8 w-8 flex items-center justify-center">
          <i class="tmlps-i-arrow-right text-2xl"></i>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    page: { type: Number, required: true },
    totalPages: { type: Number, required: true },
    totalItems: { tupe: Number, required: true },
    routeName: { type: String, required: true },
    from: { type: Number, required: true },
    to: { type: Number, required: true },
    urlQuery: { type: Object, required: false, default: () => ({}) },
  },
  computed: {
    hasMorePages() {
      return this.page < this.totalPages;
    },
  },
};
</script>
