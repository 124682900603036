<template>
  <div class="border border-gray-300 rounded-md h-10 w-64 flex items-stretch pl-3">
    <div class="flex items-center justify-center mr-3">
      <i class="tmlps-i-search font-bold text-xl"></i>
    </div>
    <input
      class="border-none bg-transparent w-full focus:outline-none focus:ring-0"
      type="text"
      name="search"
      placeholder="Buscar"
      :value="search"
      :attrs="$attrs"
      @input="handleSearch($event.target.value)"
    />
    <button
      type="button"
      class="px-2 focus:outline-none focus:ring"
      v-show="value.length > 0"
      @click="handleClear"
    >
      <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  inheritAttrs: false,
  props: {
    value: { type: String, required: true },
    debounceTime: { type: Number, default: 500 },
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    handleSearch(value) {
      this.search = value;
      this.$emit('input', value);

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.$emit('search', value);
      }, this.debounceTime);
    },

    handleClear() {
      this.search = '';
      this.$emit('input', '');
      this.$emit('search', '');
    },
  },
};
</script>
