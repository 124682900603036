<template>
  <div class="bg-white shadow-sm py-3 px-4 flex items-center h-20 w-72 m-4 relative group">
    <img
      class="h-16 w-16"
      :src="tenant.logo_url ? tenant.logo_url : `${publicPath}img/logo-placeholder.png`"
      alt="empresa"
    />
    <div class="ml-4 w-40">
      <h3 class="font-bold">{{ tenant.name }}</h3>
      <p class="text-xs">{{ tenant.projects_count }} proyectos</p>
    </div>

    <div
      class="hidden group-hover:flex items-center justify-center absolute inset-0 bg-primary-600 bg-opacity-70"
    >
      <router-link
        class="border border-white text-white px-10 py-1 rounded-md"
        :to="{ name: 'tenant-home', params: { id: tenant.id } }"
      >
        Ver proyectos
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TenantCard',
  props: {
    tenant: { type: Object, required: true },
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
  },
};
</script>
