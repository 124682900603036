<template>
  <div>
    <header class="flex items-center justify-between pb-5 border-b border-gray-200">
      <div class="font-bold">{{ tenant.tenantsTotal }} empresas registradas</div>
      <div class="flex items-center">
        <SearchInput
          placeholder="Buscar por empresa"
          :value="$route.query.search"
          @search="getTenants"
          :debounceTime="400"
        />
        <BaseButton class="h-10 w-40 ml-3" @click="showCreateModal = true">
          Agregar
        </BaseButton>
      </div>
    </header>

    <section class="mt-5">
      <div class="flex flex-wrap -m-4">
        <TenantCard v-for="tenant in tenant.tenants" :key="tenant.id" :tenant="tenant" />
      </div>

      <div class="mt-5">
        <Pagination
          :page="page"
          :total-pages="tenant.meta.last_page"
          :total-items="tenant.meta.total"
          :from="tenant.meta.from || 0"
          :to="tenant.meta.to || 0"
          :url-query="{ search: $route.query.search }"
          route-name="home"
        />
      </div>
    </section>

    <TenantCreateModal :show="showCreateModal" @close="showCreateModal = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import BaseButton from '@/components/BaseButton.vue';
import Pagination from '@/components/Pagination.vue';
import SearchInput from '@/components/SearchInput.vue';
import TenantCard from '@/components/TenantCard.vue';
import TenantCreateModal from './Tenants/components/CreateModal';

export default {
  name: 'TenantList',
  components: {
    BaseButton,
    Pagination,
    SearchInput,
    TenantCard,
    TenantCreateModal,
  },
  props: {
    page: { type: Number, required: true },
  },
  data() {
    return {
      search: '',
      showCreateModal: false,
    };
  },
  computed: {
    ...mapState(['tenant']),
  },
  methods: {
    getTenants(search) {
      this.$router.push({ name: 'home', query: { page: 1, search: search } });
    },
  },

  /**
   * LIFECYCLE HOOKS
   */
  async beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
  async beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(to, next) {
  const currentPage = parseInt(to.query.page) || 1;
  const search = to.query.search || '';

  try {
    await store.dispatch('tenant/fetchTenants', { page: currentPage, search });
    to.params.page = currentPage;
    to.query.search = search;
    next();
  } catch (error) {
    next({ name: 'network-issue' });
  }
}
</script>
