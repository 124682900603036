<template>
  <BaseModal :show="show" @close="$emit('close')">
    <h3 slot="header" class="font-bold text-base">Agregar empresa</h3>

    <div class="space-y-6">
      <ImagePreviewInput v-model="companyLogo" class="flex flex-col items-center w-48 mx-auto">
        <div slot="caption" class="mt-2 text-center">
          <p>Agrega un logo</p>
          <p class="text-xs mt-1">(La imágen de pesar menos de 300kb en formato .png o .jpg)</p>
        </div>
      </ImagePreviewInput>
      <div v-if="errors.has('company_logo')" class="text-sm text-red-600 pl-10 mt-1">
        {{ errors.first('company_logo') }}
      </div>

      <div>
        <BaseInput
          label="Nombre de la empresa"
          placeholder="Nombre de la empresa"
          :error="errors.first('name')"
          v-model="name"
        />
      </div>

      <!-- Company theme color -->
      <div>
        <div class="text-xs">Color principal</div>
        <BaseColorPicker v-model="color" class="mt-2" />
        <div v-if="errors.has('color')" class="text-sm text-red-600 pl-10 mt-1">
          {{ errors.first('color') }}
        </div>
      </div>

      <div>
        <BaseInput
          label="Subdominio"
          placeholder="Subdominio"
          :error="errors.first('domain')"
          v-model="domain"
        />
      </div>

      <h3 class="font-bold text-base text-center">Administrador de la cuenta</h3>

      <div>
        <BaseInput
          label="Nombre del contacto"
          placeholder="Nombre del contacto"
          :error="errors.first('owner_name')"
          v-model="ownerName"
        />
      </div>

      <div>
        <BaseInput
          label="Correo"
          placeholder="Correo"
          :error="errors.first('owner_email')"
          v-model="ownerEmail"
        />
      </div>
    </div>

    <div slot="footer" class="mt-6 flex items-stretch justify-between space-x-3">
      <BaseButton class="flex-grow py-3" secondary @click="$emit('close')">
        Cancelar
      </BaseButton>
      <BaseButton
        class="flex-grow py-3 inline-flex items-center justify-center"
        @click="handleAddTenant"
        :disabled="loading"
      >
        <svg
          v-if="loading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path></svg
        >Agregar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import Errors from '@/util/Errors';
import BaseButton from '@/components/BaseButton';
import BaseColorPicker from '@/components/BaseColorPicker';
import BaseInput from '@/components/BaseInput';
import BaseModal from '@/components/BaseModal';
import ImagePreviewInput from '@/components/ImagePreviewInput';

export default {
  name: 'TenantCreateModal',
  components: { BaseButton, BaseColorPicker, BaseInput, BaseModal, ImagePreviewInput },
  props: {
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      companyLogo: null,
      name: '',
      color: '',
      domain: '',
      // spaceLimit: 10,
      ownerName: '',
      ownerEmail: '',
      loading: false,
      errors: new Errors(),
    };
  },
  methods: {
    async handleAddTenant() {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('color', this.color);
      formData.append('domain', this.domain);
      // formData.append('space_limit', this.spaceLimit);
      formData.append('owner_name', this.ownerName);
      formData.append('owner_email', this.ownerEmail);

      if (this.companyLogo) {
        formData.append('company_logo', this.companyLogo);
      }

      this.loading = true;
      this.errors.clear();

      try {
        const tenant = await this.$store.dispatch('tenant/createTenant', formData);
        this.clearForm();
        this.$emit('close');
        this.$emit('created', tenant);
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.name = '';
      this.color = '';
      this.domain = '';
      this.ownerName = '';
      this.ownerEmail = '';
      this.companyLogo = '';
    },
  },
};
</script>
